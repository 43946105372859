import LegalLayout from "../../components/legal/LegalLayout"
import React from "react"
import source from "raw-loader!../../constants/legal/copyright.md"

export default function CopyrightPage() {
  return (
    <LegalLayout
      description="How Ready Five handles copyright infringement claims."
      heading="Copyright Infringement Claims"
      source={source}
      title="Copy that?"
    />
  )
}
